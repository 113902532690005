import { __awaiter, __generator } from "tslib";
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { staticOrderedPage } from "@/api/StaticPageApi";
import QrcodeVue from "qrcode.vue";
import { generateQrcode } from "@/api/CartApi";
export default defineComponent({
    name: "static-ordered-page",
    components: {
        QrcodeVue: QrcodeVue,
    },
    setup: function () {
        var _this = this;
        // const store = useStore();
        var router = useRouter();
        var route = useRoute();
        var order = ref({});
        var store = useStore();
        // const cart = computed(() => {
        //   return store.getters.currentCart;
        // });
        // const isUserAuthenticated = computed(() => {
        //   return store.getters.isUserAuthenticated;
        // });
        // const afterConfirmSuccess = () => {
        //   if (isUserAuthenticated.value) {
        //     router.push({
        //       name: "account-order-listing",
        //     });
        //   } else {
        //     router.push({
        //       name: "home",
        //     });
        //   }
        // };
        var getOrderedByUuid = function (uuid) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!uuid) return [3 /*break*/, 2];
                        return [4 /*yield*/, staticOrderedPage(uuid)
                                .then(function (_a) {
                                var data = _a.data;
                                order.value = data.data;
                            })
                                .catch(function () {
                                "_";
                            })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        router.push({ name: "home" });
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var getUuid = function () {
            return route.query.uuid;
        };
        var qrcode = ref(null);
        var downloadQrCode = function () {
            if (qrcode.value) {
                var canvasImage = qrcode.value
                    .getElementsByTagName("canvas")[0]
                    .toDataURL("image/png");
                var xhr_1 = new XMLHttpRequest();
                xhr_1.responseType = "blob";
                xhr_1.onload = function () {
                    var _a;
                    var a = document.createElement("a");
                    a.href = window.URL.createObjectURL(xhr_1.response);
                    a.download = ((_a = order.value) === null || _a === void 0 ? void 0 : _a.invoice_no) + ".png";
                    a.style.display = "none";
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                };
                xhr_1.open("GET", canvasImage);
                xhr_1.send();
            }
            // downloadGenerator();
        };
        var currentUrl = window.location.href;
        var hostname = window.location.origin;
        var qrUrl = process.env.VUE_APP_API_URL + "/api/v1/" + store.getters.getStoreData.id + "/carts/download-qrcode?uuid=" + getUuid();
        var handleGenerateQrcode = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, generateQrcode({ full_url: currentUrl }).then(function (res) {
                            console.log("%cStaticOrderedPage.vue line:205 object", "color: #007acc;", res);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        onMounted(function () {
            getOrderedByUuid(getUuid());
            handleGenerateQrcode();
        });
        return {
            // isUserAuthenticated,
            // afterConfirmSuccess,
            // cart,
            currentUrl: currentUrl,
            order: order,
            qrcode: qrcode,
            downloadQrCode: downloadQrCode,
            hostname: hostname,
            getUuid: getUuid,
            qrUrl: qrUrl,
        };
    },
});
